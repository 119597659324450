import BaseView from '@/core/view/BaseView'
import anime from 'animejs'

/**
 * 指定の範囲内で移動が可能なビュー
 *
 */
class MovableView extends BaseView {
  public element: HTMLElement

  //
  private movableY = 0

  //絶対位置
  public globalY = 0

  //ビューの高さ
  public height = 0

  //Y位置
  private _offsetY = 0
  public get offsetY(): number {
    return this._offsetY
  }
  public set offsetY(value: number) {
    this._offsetY = value
    this.updatePosition()
  }

  /**
   * コンストラクタ
   *
   */
  constructor(elem: HTMLElement) {
    super()

    this.element = elem

    const movableY = elem.getAttribute('data-movable-y') as string
    this.movableY = parseInt(movableY, 10)

    //リサイズをハンドリング
    this.listenResize()
  }

  //移動範囲内でY移動
  public set psYScale(value: number) {
    this.offsetY = -this.movableY * value + this.movableY / 2
  }

  /**
   * 移動を反映
   */
  private updatePosition() {
    // anime({
    //   targets: this.elem,
    //   translateY: this._offsetY + '%',
    //   duration: 1,
    // })
    this.element.style.transform = 'translateY(' + this._offsetY + '%)'
  }

  /**
   * 値を更新
   */
  private update(): void {
    const rect = this.element.getBoundingClientRect()
    this.globalY = rect.top + this.getScrollPositionY()
    this.height = rect.height
  }

  /**
   * 移動をクリアする
   */
  public resetPosition(): void {
    anime({
      targets: this.element,
      translateY: null,
      duration: 1,
    })
  }

  /**
   * リサイズハンドラ
   */
  onResize(): void {
    this.update()
  }
}

export default MovableView
