import BaseController from '@/core/controller/BaseController'

export interface MediaSize {
  name: string
  min: number
  max: number
  showCount: number
}

class MatchMediaDocumentViewDelegate extends BaseController {
  // ウィンドウサイズ
  private windowWidth = 0

  // デリゲートの対象
  public delegate: any

  //判別サイズ
  private mediaSizes: Array<MediaSize>

  // 現在のサイズインデックス
  private currentSizeIndex = 999999

  /**
   * コンストラクタ
   *
   */
  constructor(mediaSizes: Array<MediaSize>) {
    super()

    //
    this.mediaSizes = mediaSizes

    //デリゲートの対象
    this.delegate = this

    // ウィンドウサイズ
    this.windowWidth = window.innerWidth
  }

  /**
   * 開始
   *
   */
  public init(): void {
    this.listenResize()
  }

  /**
   * リサイズイベントハンドラ
   *
   */
  public onResize(): void {
    this.windowWidth = window.innerWidth
    this.update()
  }

  /**
   * 更新
   *
   */
  private update(): void {
    const _sizeIndex = this.getCurrentSizeIndex()
    if (_sizeIndex == undefined) return

    if (this.currentSizeIndex != _sizeIndex) {
      this.currentSizeIndex = _sizeIndex
      this.callFunction()
    }
  }

  /**
   * メソッドを強制的に呼び出します
   */
  public forceCallFunction(): void {
    this.callFunction(true)
  }

  /**
   * メソッドを呼び出します
   */
  private callFunction(isForce = false): void {
    const mediaSize = this.mediaSizes[this.currentSizeIndex]

    const _method = this.delegate['onChangeSizeTo' + mediaSize.name + 'Size']
    if (_method) {
      mediaSize.showCount = mediaSize.showCount + 1
      _method.bind(this.delegate)(mediaSize, isForce)
    }
  }

  /**
   * 現在のサイズインデックスを返します。
   *
   */
  private getCurrentSizeIndex(): number | undefined {
    let r
    for (let i = 0; i < this.mediaSizes.length; i++) {
      if (
        this.windowWidth >= this.mediaSizes[i].min &&
        this.windowWidth < this.mediaSizes[i].max
      ) {
        r = i
      }
    }
    return r
  }
}

export default MatchMediaDocumentViewDelegate
