class ArrayUtil {
  /**
   * URL解析して、クエリ文字列を返す
   * @returns {Array} クエリ文字列
   */
  public static shuffle<T>(array: Array<T>): Array<T> {
    const out = Array.from(array)
    for (let i = out.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1))
      const tmp = out[i]
      out[i] = out[r]
      out[r] = tmp
    }
    return out
  }
}

export default ArrayUtil
