import BaseView from '@/core/view/BaseView'

/**
 * ヘッダー SP
 */
class SPHeaderView extends BaseView {
  // トグルボタン
  private commonHeaderToggle!: HTMLElement

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()

    // トグルボタンを探す
    const commonHeaderToggle = document.getElementsByClassName(
      'common-header__toggle',
    )
    if (commonHeaderToggle.length) {
      this.commonHeaderToggle = commonHeaderToggle[0] as HTMLElement
      this.commonHeaderToggle!.addEventListener('click', (evt: MouseEvent) => {
        // do nothig
        this.toggle()
      })
    }
  }

  /**
   * メニュー表示トグル
   */
  public toggle(): void {
    const commonHeader = document.getElementsByTagName('body')
    if (commonHeader[0]!.classList.contains('is-nav-show')) {
      commonHeader[0]!.classList.remove('is-nav-show')
    } else {
      commonHeader[0]!.classList.add('is-nav-show')
    }
  }

  /**
   * メニューが表示中かどうか
   */
  public get isShow(): boolean {
    let r = false
    const commonHeader = document.getElementsByTagName('body')
    if (commonHeader[0]!.classList.contains('is-nav-show')) {
      r = true
    }
    return r
  }

  /**
   * SPの表示状態かどうかを返します
   */
  public isSpView(): boolean {
    let isSp = false
    if (this.commonHeaderToggle) {
      if (window.getComputedStyle(this.commonHeaderToggle).display == 'block') {
        isSp = true
      }
    }
    return isSp
  }
}

export default SPHeaderView
