import anime from 'animejs'
import Timer from '@/core/util/Timer'
import BrowserUtil from '@/core/util/BrowserUtil'
import Storage from '@/core/util/Storage'
import { MyUserType } from '@/Types'
import { ViewSize } from '@/Const'
import DocumentView from '@/core/view/DocumentView'
import HorizontalScrollSyncControler from '@/core/controller/HorizontalScrollSyncControler'
import AnchorController from '@/core/controller/AnchorController'
import MatchMediaDocumentViewDelegate from '@/core/delegate/MatchMediaDocumentViewDelegate'
import { MediaSize } from '@/core/delegate/MatchMediaDocumentViewDelegate'
import AreaCheckController from '@/core/controller/AreaCheckController'
import InviewController from '@/core/controller/InviewController'
import GalleryCarouselController from '@/controller/GalleryCarouselController'
import MainCarouselController from '@/controller/MainCarouselController'
import JSScrollController from '@/core/controller/JSScrollController'
import MovableView from '@/core/view/MovableView'
import StayTopView from '@/core/view/StayTopView'
import CoverLayoutView from '@/core/view/CoverLayoutView'
import VerticalPositionViewController from '@/core/controller/VerticalPositionViewController'
import SPHeaderView from '@/view/SPHeaderView'
import LoadingView from '@/view/LoadingView'

class MyDocument extends DocumentView {
  // SP用ヘッダー
  private spHeaderView!: SPHeaderView

  // ヘッダーの上部固定処理
  private headerStayTopView!: StayTopView

  // ローディング
  private loadingView!: LoadingView

  // レスポンシブ状態
  private matchMediaName: 'PC' | 'SP' = 'PC'

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()

    window.onbeforeunload = function () {
      // IE用。ここは空でOKです
    }
    window.onunload = function () {
      // IE以外用。ここは空でOKです
    }

    window.onpageshow = function (event: any) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }

  /**
   * ドキュメントが準備が出来たときに呼び出されます
   */
  public docReady(): void {
    super.docReady()

    // ローディングビュー
    const loadingElem = document.getElementById('loading')
    if (loadingElem) this.loadingView = new LoadingView(loadingElem)

    // 横スクロール時に連携移動させる要素処理
    const horizontalScrollSyncControler = new HorizontalScrollSyncControler()
    const commonHeader: HTMLElement = document.getElementsByClassName(
      'common-header',
    )![0] as HTMLElement
    if (commonHeader) horizontalScrollSyncControler.add(commonHeader)
    horizontalScrollSyncControler.startListen()

    // リンク処理
    const aTagElms = document.querySelectorAll('a')
    ;[].slice.call(aTagElms).forEach((item: HTMLElement, index) => {
      item.addEventListener('click', (evt: MouseEvent) => {
        const href = (evt.currentTarget! as HTMLElement).getAttribute('href')
        if (
          href &&
          href.indexOf(location.pathname) != -1 &&
          href.indexOf('#') != -1
        ) {
          // アンカーリンク処理(同一ページ内)
        } else if (href && href.substr(0, 1) == '/') {
          // サイト内リンク処理
          evt.preventDefault()
          this.pageMove(href)
        }
      })
    })

    // 画面高さをCSSで利用
    const setFillHeight = () => {
      const vh = window.innerHeight
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    // 画面のサイズ変動があった時に高さを再計算する
    if (BrowserUtil.isTablet) {
      // Tablet端末は、リサイズを無視
    } else if (BrowserUtil.isMobile) {
      // Mobile端末は、リサイズを無視
    } else {
      window.addEventListener('resize', setFillHeight)
    }
    // 初期化
    setFillHeight()

    // 画面上部で留める要素
    const tmpElms = document.querySelectorAll('.js-stay-top')
    ;[].slice.call(tmpElms).forEach((elem: HTMLElement) => {
      this.headerStayTopView = new StayTopView(elem)
      this.headerStayTopView.init()
    })

    //SP,PC の切り替わりをハンドリング
    const matchMediaDocumentViewDelegate = new MatchMediaDocumentViewDelegate([
      {
        name: 'SP',
        min: 0,
        max: 800,
        showCount: 0,
      },
      {
        name: 'PC',
        min: 800,
        max: 9999,
        showCount: 0,
      },
    ])
    matchMediaDocumentViewDelegate.delegate = this
    matchMediaDocumentViewDelegate.init()

    const handleBrowserState = (event: any) => {
      // do something
      if (event == true) {
        matchMediaDocumentViewDelegate.forceCallFunction()
      }
    }

    window.addEventListener('focus', handleBrowserState.bind(this, true))
    window.addEventListener('blur', handleBrowserState.bind(this, false))
  }

  /**
   * すべての読み込みが完了した際に呼び出されます。
   */
  public docLoadAll(): void {
    super.docLoadAll()

    // ローディングを非表示
    if (this.loadingView) this.loadingView.enter()

    // アンカーリンク
    new AnchorController()

    // ずれて動く要素の処理
    const tmpElms = document.querySelectorAll('.js-movable-view')
    ;[].slice.call(tmpElms).forEach((elem: HTMLElement) => {
      const movableView = new MovableView(elem)
      // movableView.psYScale = 0
      const useAnimationFrame = elem.getAttribute(
        'data-movable-use-animation-frame',
      ) as string

      const verticalPositionViewController = new VerticalPositionViewController(
        movableView,
        useAnimationFrame == 'true' ? true : false,
      )

      verticalPositionViewController.updateCheckWindowHeight = BrowserUtil.isMobile
        ? false
        : true
      verticalPositionViewController.startWatch()
    })

    //
    // hamburger menu (only sp)
    //
    this.spHeaderView = new SPHeaderView()

    // start
    this.start()

    //
    const clearCoverLoading = async () => {
      await Timer.wait(600)
      const bodys = document.getElementsByTagName('body')
      const body = bodys![0]
      body.classList.remove('covered-loading')
    }
    clearCoverLoading()
  }

  /**
   * 開始
   */
  public async start(): Promise<void> {
    await Timer.wait(400)
    this.watchHeadInView()
    await Timer.wait(400)
    this.watchInView()
  }

  /**
   * InView処理(Head)
   */
  watchHeadInView() {
    //
    // inView
    //
    const inviewController = new InviewController()
    inviewController.scale = 0.5
    inviewController.on('onEnter', (elem: any) => {
      elem = elem as HTMLElement
      elem.classList.add('isInview')
      // 中にあるInView要素も反応させる
      if (elem.getAttribute('data-inview-noinnerinview') != 'true') {
        const tmpElms = elem.getElementsByClassName('innerInView')
        ;[].slice.call(tmpElms).forEach((elem: HTMLElement) => {
          elem.classList.add('isInview')
        })
      }
    })
    const list: HTMLCollection = document.getElementsByClassName('inViewH')
    Array.prototype.slice.call(list).forEach((elem) => {
      inviewController.add(elem)
    })
    inviewController.init()
  }

  /**
   * InView処理
   */
  watchInView() {
    //
    // inView 75%
    //
    const inviewController = new InviewController()
    inviewController.scale = 0.2
    inviewController.on('onEnter', (elem: any) => {
      elem = elem as HTMLElement
      elem!.classList.add('isInview')
      // 中にあるInView要素も反応させる

      // console.log(elem.getAttribute('data-inview-noinnerinview') == true)
      // console.log(elem.getAttribute('data-inview-noinnerinview') == 'true')
      if (elem.getAttribute('data-inview-noinnerinview') != 'true') {
        const tmpElms = elem.getElementsByClassName('innerInView')
        ;[].slice.call(tmpElms).forEach((elem: HTMLElement) => {
          elem.classList.add('isInview')
        })
      }
    })
    const list: HTMLCollection = document.getElementsByClassName('inView')
    Array.prototype.slice.call(list).forEach((elem) => {
      inviewController.add(elem)
    })
    inviewController.init()

    //
    // inView 85%
    //
    // const inviewController2 = new InviewController()
    // inviewController2.scale = 0.85
    // inviewController2.on('onEnter', (elem: any) => {
    //   elem = elem as HTMLElement
    //   elem!.classList.add('isInview')
    // })
    // list = document.getElementsByClassName('inView-s')
    // Array.prototype.slice.call(list).forEach((elem) => {
    //   inviewController2.add(elem)
    // })
    // inviewController2.init()
  }

  /**
   * アンカーリンク
   */
  // private anchorScroll(href: string): void {
  //   const targetElem = document.getElementById(href.substr(1))
  //   if (targetElem) {
  //     const jsScrollController = new JSScrollController()
  //     jsScrollController.run(targetElem, true)
  //   }
  // }

  //
  private videoCoverLayoutView!: CoverLayoutView

  /**
   * SP、PCの切り替え
   */
  // SP
  private async onChangeSizeToSPSize(mediaSize: MediaSize, forceCall: boolean) {
    console.log('SP')
    console.log(mediaSize)
    this.matchMediaName = 'SP'

    // ビデオを挿入
    const videoWrapElm = document.getElementById('intro-movie-wrap')
    if (videoWrapElm) {
      const tmpElem = document.getElementById('video-sp')
      if (tmpElem) {
        if (forceCall) {
          videoWrapElm.innerHTML = ''
          await Timer.wait(100)
        }
        videoWrapElm.innerHTML = tmpElem.innerHTML
        const videoElm = videoWrapElm.children[0] as HTMLElement
        if (videoElm) {
          const vWidthStr = videoElm.getAttribute('width')
          const vHeightStr = videoElm.getAttribute('height')
          const vWidth = parseInt(vWidthStr ? vWidthStr : '0', 10)
          const vHeight = parseInt(vHeightStr ? vHeightStr : '0', 10)
          if (this.videoCoverLayoutView) this.videoCoverLayoutView.destroy()
          this.videoCoverLayoutView = new CoverLayoutView(
            videoElm,
            vWidth,
            vHeight,
            'cover',
          )
          this.videoCoverLayoutView.init()
        }
      }
    }
  }
  // PC
  private onChangeSizeToPCSize(mediaSize: MediaSize, forceCall: boolean) {
    console.log('PC')
    console.log(mediaSize)
    this.matchMediaName = 'PC'

    // ビデオを挿入
    const videoWrapElm = document.getElementById('intro-movie-wrap')
    if (videoWrapElm) {
      const tmpElem = document.getElementById('video-pc')
      if (tmpElem) {
        videoWrapElm.innerHTML = tmpElem.innerHTML
        const videoElm = videoWrapElm.children[0] as HTMLElement
        if (videoElm) {
          const vWidthStr = videoElm.getAttribute('width')
          const vHeightStr = videoElm.getAttribute('height')
          const vWidth = parseInt(vWidthStr ? vWidthStr : '0', 10)
          const vHeight = parseInt(vHeightStr ? vHeightStr : '0', 10)
          if (this.videoCoverLayoutView) this.videoCoverLayoutView.destroy()
          this.videoCoverLayoutView = new CoverLayoutView(
            videoElm,
            vWidth,
            vHeight,
            'contain',
          )
          this.videoCoverLayoutView.init()
        }
      }
    }
  }

  /**
   * サイト内ページ移動
   */
  private async pageMove(href: string): Promise<void> {
    const bodys = document.getElementsByTagName('body')
    const body = bodys![0]
    if (body) {
      // SP表示でメニューが表示中の場合は閉じる
      if (this.spHeaderView.isSpView() && this.spHeaderView.isShow) {
        this.spHeaderView.toggle()
        await Timer.wait(100)
      }

      // UIが表示された状態にする（上位ラッパー要素の高さをトる）
      let container
      let rect
      if (this.matchMediaName == 'SP') {
        const tmpElms = document.querySelectorAll('.container')
        container = tmpElms.length ? (tmpElms[0] as HTMLElement) : null
        rect = container!.getBoundingClientRect()
        if (container && rect) {
          container.style.position = 'fixed'
          container.style.top = rect.y + 'px'
        }
      }

      body.classList.add('will-leave')

      // ローディング表示
      if (this.loadingView) {
        const force = this.headerStayTopView
          ? this.headerStayTopView.isFixed
          : false
        this.loadingView.willLeave(href, force)
      }

      await Timer.wait(1)
      body.classList.add('leave')

      // ローディング表示
      let panelCount = 0
      if (this.loadingView) {
        panelCount = this.loadingView.leave()
      }

      // Loadingの横ランイが画面全部を覆う時間だけ待つ
      await Timer.wait(panelCount * 80)

      // 上位ラッパーを元の状態に戻す
      if (this.matchMediaName == 'SP' && container && rect) {
        container.style.position = 'static'
        container.style.top = '0px'
        this.setScrollPositionY(-rect.y)
      }
    }

    location.href = href
  }
}
new MyDocument()
