import Timer from '@/core/util/Timer'
import ArrayUtil from '@/core/util/ArrayUtil'
import Storage from '@/core/util/Storage'
import BaseView from '@/core/view/BaseView'

/**
 * ローディングビュー
 */
class LoadingView extends BaseView {
  // 要素
  private elem: HTMLElement

  //入れ物
  private loadinWrap!: HTMLElement

  // body
  private body: HTMLElement

  /**
   * コンストラクタ
   *
   */
  constructor(elem: HTMLElement) {
    super()

    this.elem = elem

    const elems = this.elem.querySelectorAll('.loading-wrap')
    if (elems.length) {
      this.loadinWrap = elems[0] as HTMLElement
    }

    // ヘッダー
    this.body = document.getElementsByTagName('body')![0]

    // Loading表現の切り替えよう
    if (Storage.storageAvailable('sessionStorage')) {
      const coveredLoading = sessionStorage.getItem('covered-loading')
      if (coveredLoading == 'true') {
        const bodys = document.getElementsByTagName('body')
        const body = bodys![0]
        body.classList.add('covered-loading')
      }
      sessionStorage.removeItem('covered-loading')
    }

    // this.listenResize()
    // this.listenScroll()
  }

  /**
   * 表示
   */
  public async enter(): Promise<void> {
    this.updatePanel()
    await Timer.wait(10)
    this.body.classList.add('js-loaded')
  }

  /**
   * ページを離れるときの処理
   *
   */
  public willLeave(nextHref: string, force: boolean): void {
    this.body.classList.add('js-will-leave')

    // Loading表現の切り替えよう
    if (Storage.storageAvailable('sessionStorage')) {
      let coveredLoading = false
      if (nextHref == '/') {
        coveredLoading = true
      } else if (location.pathname == '/') {
        coveredLoading = true
        if (force) {
          coveredLoading = false
        }
      }
      if (coveredLoading) {
        sessionStorage.setItem('covered-loading', 'true')
        const bodys = document.getElementsByTagName('body')
        const body = bodys![0]
        body.classList.add('covered-loading')
      }
    }
  }

  /**
   * 非表示
   */
  private updatePanel(): number {
    let panelCount = 0
    if (this.loadinWrap) {
      const panelHeight = this.loadinWrap.getBoundingClientRect().height

      // 画面の高さ分の要素を入れ込む
      this.loadinWrap.innerHTML = ''
      panelCount = Math.round(window.innerHeight / panelHeight) + 1

      // インデックスリストを作成
      let indexList: Array<number> = []
      for (let i = 0; i < panelCount; i++) {
        indexList.push(i)
      }

      // インデックスリストシャッフル
      indexList = ArrayUtil.shuffle(indexList)
      indexList = indexList

      for (let i = 0; i < panelCount; i++) {
        const div = document.createElement('div')
        div.classList.add('panel-' + indexList[i])
        this.loadinWrap.appendChild(div)
      }
    }
    return panelCount
  }

  /**
   * 非表示
   */
  public leave(): number {
    this.updatePanel()
    const leaveExe = async () => {
      await Timer.wait(50)
      this.body.classList.add('js-leave')
    }
    const panelCount = this.updatePanel()
    leaveExe()
    return panelCount
  }

  // /**
  //  * リサイズイベントハンドラ
  //  *
  //  */
  // public onResize(): void {
  //   // do nothing.
  // }

  /**
   * 破壊
   */
  public dispose(): void {
    // this.unListenResize()
    // this.unListenScroll()
  }

  // /**
  //  * スクロールイベントハンドラ
  //  */
  // onScroll(): void {
  //   // スクロール位置
  //   const scrollY = this.getScrollPositionY()
  // }
}

export default LoadingView
