class Storage {
  /**
   * WebStorageに対応しているかどうか返します
   * https://qiita.com/uralogical/items/ade858ccfa164d164a3b
   * storageAvailable('sessionStorage')
   */
  public static storageAvailable(type: 'sessionStorage'): boolean{
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
      var storage = window[type]
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage.length !== 0;
    }
  }
}

export default Storage
