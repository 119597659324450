import BaseView from '@/core/view/BaseView'
import anime from 'animejs'

/**
 *
 *
 *
 */
class StayTopView extends BaseView {
  private elem: HTMLElement

  // とどめているかどうか
  private _isFixed = false
  public get isFixed(): boolean {
    return this._isFixed
  }

  // 位置
  private positionY = 0

  /**
   * コンストラクタ
   *
   */
  constructor(elem: HTMLElement) {
    super()

    this.elem = elem

    // const rect = this.elem.getBoundingClientRect()
    // this.elemWidth = rect.width;
    // this.elemHeight = rect.height;

    // this.positionY =
  }

  public init(): void {
    const rect = this.elem.getBoundingClientRect()
    this.positionY = rect.top + this.getScrollPositionY()
    this.listenResize()
    this.listenScroll()
  }

  /**
   * 値を更新
   */
  private update(): void {
    const scrY = this.getScrollPositionY()

    if (scrY >= this.positionY && this._isFixed == false) {
      // 留める
      this._isFixed = true
      this.elem.classList.add('fixed')
    } else if (scrY < this.positionY && this._isFixed == true) {
      // 解除
      this._isFixed = false
      this.elem.classList.remove('fixed')
    }
  }

  /**
   * 値を更新
   */
  onScroll(scrollY: number, scrollX: number): void {
    this.update()
  }

  /**
   * リサイズハンドラ
   */
  onResize(): void {
    this.update()
  }
}

export default StayTopView
