import { EventEmitter } from 'eventemitter3'
import BrowserUtil from '@/core/util/BrowserUtil'

class BaseView extends EventEmitter {
  // タッチ端末かどうか
  private canTouch = false

  // 前回のスクロール位置
  private scrollPrevPositonY = 0

  // スクロール方向
  protected scrollDirection = 'down'

  // レンダリング中かどうか
  private rendering = false

  // レンダリングID
  private renderid: number | null = null

  // スクロール位置を見るDOM
  private scrollWatchDom!: Element | Window

  // リサイズのハンドラ
  private onResizeHandler: () => void

  // スクロールハンドラ
  private onScrollHandler: () => void

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()

    //タッチ端末かどうか
    this.canTouch = window.ontouchstart === null ? true : false

    //リサイズハンドラ
    this.onResizeHandler = this.onResize.bind(this)

    // スクロールハンドラ
    this.onScrollHandler = this._onScroll.bind(this)

    // スクロール位置を見る要素
    this.scrollWatchDom = BrowserUtil.isIE
      ? document.getElementsByTagName('html')![0]
      : window
  }

  /**
   * リサイズイベントをハンドリング
   *
   */
  public listenResize(): void {
    window.addEventListener('resize', this.onResizeHandler)
    this.onResize()
  }

  /**
   * リサイズイベントをアンハンドリング
   *
   */
  public unListenResize(): void {
    window.removeEventListener('resize', this.onResizeHandler)
  }

  /**
   * リサイズイベントハンドラ
   *
   */
  public onResize(): void {
    //
  }

  /**
   * スクロールイベントをハンドリング
   *
   */
  public listenScroll(): void {
    window.addEventListener('scroll', this.onScrollHandler)
    this._onScroll()
  }

  /**
   * スクロールイベントをアンハンドリング
   *
   */
  public unListenScroll(): void {
    window.removeEventListener('scroll', this.onScrollHandler)
  }

  /**
   * スクロールイベントハンドラ
   */
  _onScroll(): void {
    let newScrollPosition
    let newScrollPositionX
    if (this.scrollWatchDom instanceof Window) {
      newScrollPosition = this.scrollWatchDom.scrollY
      newScrollPositionX = this.scrollWatchDom.scrollX
    } else {
      newScrollPosition = this.scrollWatchDom.scrollTop
      newScrollPositionX = this.scrollWatchDom.scrollLeft
    }
    this.scrollDirection =
      this.scrollPrevPositonY < newScrollPosition ? 'down' : 'up'
    this.scrollPrevPositonY = newScrollPosition
    this.onScroll(newScrollPosition, newScrollPositionX)
  }
  onScroll(scrollY: number, scrollX: number): void {
    // do nothing.
    // console.log(scrollY, scrollX)
  }

  /**
   * スクロール位置を返します
   *
   */
  public getScrollPositionY(): number {
    let newScrollPosition
    if (this.scrollWatchDom instanceof Window) {
      newScrollPosition = this.scrollWatchDom.scrollY
    } else {
      newScrollPosition = this.scrollWatchDom.scrollTop
    }
    return newScrollPosition
  }
  public getScrollPositionX(): number {
    let newScrollPositionX
    if (this.scrollWatchDom instanceof Window) {
      newScrollPositionX = this.scrollWatchDom.scrollX
    } else {
      newScrollPositionX = this.scrollWatchDom.scrollLeft
    }
    return newScrollPositionX
  }

  /**
   * スクロール位置を設定します
   *
   */
  public setScrollPositionY(value: number): void {
    window.scrollTo(0, value)
  }

  /**
   * スクロールイベントをハンドリング
   *
   */
  public listenRender(): void {
    if (this.rendering) return
    this.rendering = true
    this._onRender()
  }

  /**
   *
   *
   */
  public unListenRender(): void {
    this.rendering = false
    // window.cancelRequestAnimationFrame(this.renderid)
    this.renderid = null
  }

  /**
   * レンダリング
   */
  private _onRender() {
    if (!this.rendering) return
    this.renderid = window.requestAnimationFrame(this._onRender.bind(this))
    this.onRender()
  }
  public onRender(): void {
    // do nothing.
  }
}

export default BaseView
