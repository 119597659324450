import BaseView from '@/core/view/BaseView'
import anime from 'animejs'

/**
 *
 * 要素をbackground-clip coverと同じようにレイアウトします
 *
 */
class CoverLayoutView extends BaseView {
  // 要素
  private elem: HTMLElement

  // 要素を囲む要素
  private wrapElem: HTMLElement | null

  // 要素の幅
  private elemWidth = 0

  // 要素の高さ
  private elemHeight = 0

  // update ファンクション
  private updateFunc: any

  // レイアウト方式
  private _layoutStyle: 'cover' | 'contain'

  /**
   * コンストラクタ
   *
   */
  constructor(
    elem: HTMLElement,
    width: number,
    height: number,
    layoutStyle: 'cover' | 'contain' = 'cover',
  ) {
    super()

    this.elem = elem

    this._layoutStyle = layoutStyle

    // const rect = this.elem.getBoundingClientRect()
    this.elemWidth = width
    this.elemHeight = height

    console.log(this.elemWidth)
    console.log(this.elemHeight)

    this.wrapElem = this.elem.parentElement

    if (layoutStyle == 'cover') {
      this.updateFunc = this.updateCover
    } else {
      this.updateFunc = this.updateContain
    }
  }

  public init(): void {
    this.listenResize()
  }

  /**
   * 値を更新
   */
  private update(): void {
    this.updateFunc()
  }

  /**
   * 値を更新(Contain)
   */
  private updateContain(): void {
    //位置移動
    const offsetX = 0
    let offsetY = 0

    if (this.wrapElem) {
      const rect = this.wrapElem.getBoundingClientRect()
      const areaW = rect.width
      const areaH = rect.height

      console.log(areaW)
      console.log(areaH)

      const newW = areaW
      const newH = areaW * (this.elemHeight / this.elemWidth)
      offsetY += (areaH - newH) / 2
      this.elem.style.width = newW + 'px'
      this.elem.style.height = newH + 'px'
      this.elem.style.left = offsetX + 'px'
      this.elem.style.top = offsetY + 'px'
    }
  }

  /**
   * 値を更新(Cover)
   */
  private updateCover(): void {
    //溢れ
    const paddintW = 4
    const paddintH = 4

    //位置移動
    let offsetX = -paddintW
    let offsetY = -paddintH

    if (this.wrapElem) {
      const rect = this.wrapElem.getBoundingClientRect()
      const areaW = rect.width + paddintW * 2
      const areaH = rect.height + paddintH * 2

      let newW = areaW
      let newH = areaW * (this.elemHeight / this.elemWidth)

      if (areaH > newH) {
        //縦足りない
        newW = areaH * (this.elemWidth / this.elemHeight)
        newH = areaH

        offsetX += (areaW - newW) / 2
      } else {
        offsetY += (areaH - newH) / 2
      }
      this.elem.style.width = newW + 'px'
      this.elem.style.height = newH + 'px'
      this.elem.style.left = offsetX + 'px'
      this.elem.style.top = offsetY + 'px'
    }
  }

  /**
   * リサイズハンドラ
   */
  onResize(): void {
    this.update()
  }

  /**
   * 破壊
   */
  destroy(): void {
    this.unListenResize()
  }
}

export default CoverLayoutView
