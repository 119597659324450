import BaseController from '@/core/controller/BaseController'

interface InViewPosition {
  y: number
  h: number
}

interface InViewConfig {
  elem: HTMLElement
  vertical: 'top' | 'middle'
}
/**
 *
 */
class InviewController extends BaseController {
  //初期化積みかどうか
  private inited = false

  // 現在のスクロール位置
  private scrollY = 0

  //対象要素リスト
  private targets: Array<InViewConfig> = []

  // 反応エリア
  private _scale = 1

  /**
   * 反応エリア
   */
  set scale(val: number) {
    this._scale = val
  }

  /**
   * ウィンドウの高さ
   */
  get windowHeight(): number {
    return window.innerHeight - 300 * this._scale
  }

  /**
   * 表示領域を返します
   *
   */
  get visibleAreaRect(): InViewPosition {
    const data: InViewPosition = {
      y: this.scrollY,
      h: this.windowHeight,
    }
    return data
  }

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()
  }

  /**
   * 初期化
   *
   */
  init(): void {
    if (this.inited) return
    this.inited = true
    this.listenResize()
    this.listenScroll()
  }

  /**
   * 破壊
   *
   */
  public dispose(): void {
    if (!this.inited) return
    this.unListenResize()
    this.unListenResize()
  }

  /**
   * ハンドラ
   *
   */
  public onResize(): void {
    this.updateArea()
    this.testRender()
  }
  public onScroll(): void {
    this.updateArea()
    this.testRender()
  }

  /**
   * 位置情報を確認更新
   *
   */
  private updateArea(): void {
    this.scrollY = this.getScrollPositionY()
  }

  /**
   * 要素の追加
   */
  add(elem: HTMLElement): void {
    const vertial: 'top' | 'middle' | null = elem.getAttribute(
      'data-inview-vertial',
    ) as 'top' | 'middle' | null
    this.targets.push({
      elem: elem,
      vertical: vertial ? vertial : 'top',
    })
    this.testRender()
  }

  /**
   * あたっているかどうかを返します
   *
   */
  isHit(
    rectA: InViewPosition,
    rectB: InViewPosition,
    vertical: 'top' | 'middle' = 'top',
  ): boolean {
    const bA = rectA.y + rectA.h // Bottom of rectA
    const bB = rectB.y + rectB.h // Bottom of rectB
    const hitY =
      bA > rectB.y + (vertical == 'middle' ? rectB.h / 2 : 0) && rectA.y < bB // True if hit on y-axis
    return hitY
  }

  /**
   * 要素の領域を返します
   *
   */
  getDomAreaRect(elem: HTMLElement): InViewPosition {
    const rect = elem.getBoundingClientRect()
    return {
      y: rect.top + this.scrollY,
      h: elem.offsetHeight,
    }
  }

  /**
   * スクロールイベントをハンドリング
   *
   */
  public listenScroll(): void {
    super.listenScroll()
  }

  /**
   * スクロール位置を返します
   *
   */
  public getScrollPositionY(): number {
    return super.getScrollPositionY()
  }

  /**
   * チェック
   *
   */
  private testRender(): void {
    if (!this.targets.length) return
    this.targets.forEach((config, index) => {
      if (
        this.isHit(
          this.visibleAreaRect,
          this.getDomAreaRect(config.elem),
          config.vertical,
        )
      ) {
        this.emit('onEnter', config.elem)
        this.targets.splice(index, 1)
        return this.testRender()
      }
    })
  }
}

export default InviewController
