class Timer {
  /**
   * Promises式タイマーを返します
   * @returns {Promise} クエリ文字列
   */
  public static wait(interval: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, interval))
  }
}

export default Timer
