import anime from 'animejs'
import BaseController from '@/core/controller/BaseController'

/**
 *
 */
class JSScrollController extends BaseController {
  //
  public offsetY = 0

  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()
  }

  /**
   * 実行
   */
  public run(elem: HTMLElement, center = false, duration = 700): void {
    const rect = elem.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollY = rect.top + scrollTop

    // .main要素が ページに入ってくる際に動くので量を反映
    const main: HTMLElement = document.getElementsByClassName(
      'main',
    )![0] as HTMLElement
    const mainStyle = window.getComputedStyle(main)
    const wrapOffsetY = parseInt(mainStyle.marginTop.replace('px', ''), 10)

    // 縦センターにする
    if (center) {
      const winHeight = document.documentElement.clientHeight
      if (winHeight - this.offsetY > rect.height) {
        scrollY -= (winHeight - rect.height) / 2
      }
    }

    scrollY -= wrapOffsetY + this.offsetY

    anime({
      targets: 'html,body',
      scrollTop: scrollY,
      duration: duration,
      easing: 'easeInOutSine',
    })
  }
}

export default JSScrollController
