import BaseController from '@/core/controller/BaseController'
import MovableView from '@/core/view/MovableView'

/**
 *
 */
class VerticalPositionViewController extends BaseController {
  // positon:fixedの要素かどうか
  private isFixed = false

  private movableView: MovableView

  private topMargin = 0

  private bottomMargin = 0

  private isWatching = false

  private winHeight = 0

  public updateCheckWindowHeight = true

  //AnimationFrameを使ってレンダリングするかどうか
  private useAnimationFrame = false

  private prevScrollY = 0

  /**
   * コンストラクタ
   *
   */
  constructor(movableView: MovableView, useAnimationFrame = false) {
    super()

    this.movableView = movableView

    this.useAnimationFrame = useAnimationFrame

    // positon:fixedの要素かどうか
    if (window.getComputedStyle(this.movableView.element).position == 'fixed') {
      this.isFixed = true
    }
  }

  /**
   *
   *
   */
  public startWatch(): void {
    if (this.isWatching) return
    this.isWatching = true
    this.winHeight = window.innerHeight

    //ネイティブのスクロールをハンドリング
    if (this.useAnimationFrame) {
      this.listenRender()
    } else {
      this.listenScroll()
    }
  }

  /**
   *
   *
   */
  public stopWatch(): void {
    if (!this.isWatching) return
    this.isWatching = false

    //ネイティブのスクロールをハンドリング解除
    if (this.useAnimationFrame) {
      this.unListenRender()
    } else {
      this.unListenScroll()
    }

    //位置を元の位置に
    this.movableView.resetPosition()
  }

  /**
   * 更新
   */
  onScroll(): void {
    this.update()
  }

  /**
   * レンダリング
   */
  public onRender(): void {
    // do nothing.
    const scrollY = this.getScrollPositionY()
    if (this.prevScrollY != scrollY) {
      this.prevScrollY = scrollY
      this.update()
    }
  }

  /**
   * 更新
   */
  private update(): void {
    const scrollY = this.getScrollPositionY()
    if (this.updateCheckWindowHeight) {
      this.winHeight = window.innerHeight
    }

    //min:下位置
    let min = -this.winHeight - this.topMargin
    if (!this.isFixed) {
      min += this.movableView.globalY
    }

    //max:上位置
    let max = this.movableView.height + this.bottomMargin
    if (!this.isFixed) {
      max += this.movableView.globalY
    }

    //どの位置にいるか0~1の範囲で算出
    if (scrollY > min && scrollY < max) {
      const pYs = (scrollY - min) / (this.winHeight + this.movableView.height)
      this.movableView.psYScale = pYs
    } else if (scrollY < min) {
      this.movableView.psYScale = 0
    } else if (scrollY > min) {
      this.movableView.psYScale = 1
    }
  }
}

export default VerticalPositionViewController
