import BaseView from '@/core/view/BaseView'

/**
 * ドキュメントクラス
 *
 */
class DocumentView extends BaseView {
  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()

    //productionビルドの場合は、console.logを殺す
    if (WEBPACK_MODE == 'production') {
      console.log = function () {
        // do nothing.
      }
    }

    if (document.readyState == 'interactive') {
      this.docReady()
      this.docReadyAfter()
    } else {
      // $(document).ready(function() { ...
      document.addEventListener('DOMContentLoaded', () => {
        this.docReady()
        this.docReadyAfter()
      })
    }

    if (document.readyState == 'complete') {
      // $('body').addClass('loaded');
      document.getElementsByTagName('body')[0].classList.add('loaded')
      this.docLoadAll()
      this.docLoadAllAfter()
    } else {
      window.addEventListener('load', () => {
        document.getElementsByTagName('body')[0].classList.add('loaded')
        this.docLoadAll()
        this.docLoadAllAfter()
      })
    }
  }

  /**
   * ドキュメントが準備が出来たときに呼び出されます
   */
  public docReady(): void {
    // do nothing.
  }

  /**
   * docReady() の直後に呼び出されます
   */
  public docReadyAfter(): void {
    // do nothing.
  }

  /**
   * すべての読み込みが完了した際に呼び出されます。
   */
  public docLoadAll(): void {
    // do nothing.
  }

  /**
   * docLoadAll() の直後に呼び出されます
   */
  public docLoadAllAfter(): void {
    // do nothing.
  }
}

export default DocumentView
