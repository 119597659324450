import BaseController from '@/core/controller/BaseController'
import JSScrollController from '@/core/controller/JSScrollController'

class AnchorController extends BaseController {
  /**
   * コンストラクタ
   *
   */
  constructor() {
    super()

    if (location.hash) {
      this.onChangeHash()
    }
    window.addEventListener('hashchange', this.onChangeHash.bind(this))
  }

  private onChangeHash(): void {
    const tmpElms = document.querySelectorAll('[data-id]')
    ;[].slice.call(tmpElms).forEach((item: HTMLElement) => {
      if ('#' + item.getAttribute('data-id') == location.hash) {
        const scroller = new JSScrollController()
        scroller.offsetY = 100
        scroller.run(item, true)
      }
    })
    // location.hash
  }
}

export default AnchorController
