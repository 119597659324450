import { UAParser } from 'ua-parser-js'
const oUAParser = new UAParser()
class BrowserUtil {
  /**
   * Touch環境かどうか
   */
  public static get isTouchDevice(): boolean {
    return 'ontouchstart' in window
  }

  /**
   * IE環境かどうか
   */
  public static get isIE(): boolean {
    return oUAParser.getBrowser().name == 'IE' ? true : false
  }

  /**
   * Tablet環境かどうか
   */
  public static get isTablet(): boolean {
    let isTablet = oUAParser.getDevice().type == 'tablet'
    if (
      !isTablet &&
      oUAParser.getOS().name == 'Mac OS' &&
      BrowserUtil.isTouchDevice
    ) {
      isTablet = true
    }
    return isTablet
  }

  /**
   * Mobile環境かどうか
   */
  public static get isMobile(): boolean {
    const isMobile = oUAParser.getDevice().type == 'mobile'
    return isMobile
  }
}

export default BrowserUtil
